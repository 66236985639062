// packages
import PropTypes from 'prop-types';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjs from 'dayjs';

// components
import FormTemplate from "components/BTDUI/FormTemplate";
import BTDProgress from 'components/BTDUI/BTDProgress';
import Duration from 'components/BTDUI/Duration';

// Other
import useApi from 'hooks/useApi';
import './styles.css';

const ProjectReport = ({
    id = null,
    onClose = () => {alert('onClose is not set!')}
}) => {

    dayjs.extend(advancedFormat);

    const { data: report, errors, isLoading } = useApi('/projects/' + id + '/report');

    console.log('report: ', report);
    

    return (
        <FormTemplate onClose={() => onClose()} size='large'>
            {isLoading ? 
                <BTDProgress type='circular' height={50} style={{margin: '40px auto'}} width={50} />
            : 
                Object.keys(report).map((key, index) => (
                    <div key={index}>
                        <div className='ProjectReport__header'>
                            <h3 className='bold'>{key}</h3>
                            <p className='bold'>Total time: <Duration seconds={report[key].total_seconds}/></p>
                        </div>
                        <div className='ProjectReport__times'>
                        {report[key].times.map((item, index) => (
                            <div className='ProjectReport__times__time' key={index}>
                                <p>{dayjs(item.started_at).format('Do')}</p>
                                <h4>{item?.task?.name}</h4>
                                <p className='right'><Duration seconds={item.seconds}/></p>
                            </div>
                        ))}
                        </div>
                    </div>
                ))
            }
        </FormTemplate>
    );
}

ProjectReport.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClose: PropTypes.func
}

export default ProjectReport;