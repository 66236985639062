// Packages
import PropTypes from 'prop-types';

const StatsIcon = ({ 
    height = "44px", 
    onClick = () => {}, 
    styles = {}, 
    width =  "57px"
}) => {
    
    return(
        <div onClick={onClick} style={{display: 'inline-block', ...styles}}>
            <svg width={width} height={height} viewBox="0 0 64 64" fill="#000000">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier"> 
                    <g fill="none" fillRule="evenodd"> 
                        <rect width={width} height={height} x="4" y="11" fill="#80D25B"></rect> 
                        <path stroke="#22BA8E" strokeLinecap="square" strokeWidth="2" d="M15 12L15 54M38 12L38 54M27 12L27 54M50 12L50 54"></path> 
                        <circle cx="9" cy="43" r="2" fill="#D650C7"></circle> 
                        <circle cx="21" cy="31" r="2" fill="#D650C7"></circle> 
                        <circle cx="33" cy="37" r="2" fill="#D650C7"></circle> 
                        <circle cx="44" cy="23" r="2" fill="#D650C7"></circle> 
                        <circle cx="56" cy="23" r="2" fill="#D650C7"></circle> 
                        <polyline stroke="#D650C7" strokeLinecap="round" strokeWidth="2" points="9.197 42.868 21.177 31.012 33.005 37.064 44.137 23.086 56.079 23.086"></polyline> 
                    </g>
                </g>
            </svg>
        </div>
    )
}

StatsIcon.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    styles: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default StatsIcon;