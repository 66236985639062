// Packages
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// components
import Skeleton from 'components/BTDUI/Skeleton';
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import TextEditor from 'components/BTDUI/fields/BTDTextEditor';
import BTDButton from 'components/BTDUI/BTDButton';
import ImageUpload from 'components/BTDUI/fields/ImageUpload';
import UserInitials from 'components/BTDUI/UserInitials';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import SpeechIcon from 'assets/kakao-talk.svg';
import NoSpeechIcon from 'assets/no-comment.svg';
import Duration from 'components/BTDUI/Duration';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import DownloadPDF from 'components/BTDUI/DownloadPDF';
import HelpscoutIcon from 'assets/helpscout-logo.svg';
import BTDDatepicker from 'components/BTDUI/fields/BTDDatepicker';

// Context
import { useTimer } from 'context/Timer';
import { useProject } from 'context/Project';
import { useStatus } from 'context/Status';
import { useUser } from 'context/User';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const TaskViewScreen = ({ onClose, onSubmit, projectId, taskId }) => {

	const [hasChanges, setHasChanges] = useState(false);
	const [nameFormActive, setNameFormActive] = useState(false);
	const [descriptionFormActive, setDescriptionFormActive] = useState(false);
	const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
	const [totalTime, setTotalTime] = useState(null)

	const { data: task, destroy, errors, isLoading, onChange } = useApi('/tasks/' + taskId + '?with=attachments,times,project,user,status');
	const { put } = useApi('/tasks/' + taskId, false);
	const { destroy: destroyAttachment } = useApi('/attachments/', false);
	const { destroy: destroyTime, errors: timeErrors, put: updateTime } = useApi('/times', false);

	const { statuses } = useStatus();
	const { users } = useUser()
	const { projects } = useProject();
	const { show, start, stop } = useTimer();

    // Load Item values into form
	useEffect(() => {

		if ( task.length != 0 )
		{	
            setData(task);
		}

	}, [task]);

	useEffect(() => {

		if( task.times ) {

			let total = 0;
				for( let i = 0; i < task.times.length; i++ ) {
					total += task.times[i].seconds;
				}
				setTotalTime(total);
		}
	}, [task]);

	useEffect(() => {

		if( show ) {
			onChange();
		}

	// eslint-disable-next-line
	},[show])

	const deleteItem = async () => {

		if( await destroy('task', 'Task Deleted!') ){

			onSubmit();
		}
    }

	const deleteAttachment = async (id) => {

		// await setParams(id);

		if( await destroyAttachment('attachment', 'Attachment Deleted!', id) ){

			onChange();
		}
    }

	const deleteTime = async (id) => {

		if( await destroyTime('time', 'Time Deleted!', '/' + id) ) {

			onChange();
		}
    }

	return (
		<FormTemplate size='large' onClose={() => {

			if(hasChanges) {
				onSubmit();
			}
			else {
				onClose();
			}
		}}>

			<div className='TaskViewScreen'>

				<div className='TaskViewScreen__nameWrapper'>

					{nameFormActive ?
						<form onSubmit={async (e) => {
							e.preventDefault();
							if( await put({name: changedData.name}) ) {
								setHasChanges(true);
								setNameFormActive(false);
							}
						}}>
							<BTDTextField 
								error={errors && errors.name ? errors.name : null} 
								label='Name'
								onChange={(value) => {
									setData({...data, name: value});
									setChangedData({...changedData, name: value});
								}} 
								required={true}
								value={data.name ?? ''} 
							/>
						</form>
					:
						<h2 className='TaskViewScreen__name' onClick={() => setNameFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : data.name}</h2>
					}

					{! isLoading && task.deleted_at && 
						<h1 className='TaskViewScreen__deleted red bold'>{<>&#91;</>}DELETED{<>&#93;</>}</h1>
					}
				</div>


				<div className='TaskViewScreen__buttons'>

					<ul className='TaskViewScreen__buttons__statuses'>
						{statuses && statuses.map((status, index) => {
							return(
								<label key={index} className="TaskViewScreen__buttons__statuses__status" style={{backgroundColor: status.colour}}>
									<input
										checked={data.status_id === status.id}
										className="TaskViewScreen__buttons__statuses__status__input"
										onChange={async () => {
											if( await put({status_id: status.id}) ) {
												if(status.id == 5) {
													setData({...data, status_id: status.id, daily_update: 'yes'});
												} else {
													setData({...data, status_id: status.id});
												}
												setHasChanges(true);
											}
											}}
										style={{backgroundColor: status.colour}}
										type="radio"
										value={data.status_id}
										/>
										<div
											className="TaskViewScreen__buttons__statuses__status__leftTriangle"
										/>
											{status.name}
										<div
											className="TaskViewScreen__buttons__statuses__status__rightTriangle"
											style={{borderLeft: `8px solid ${status.colour}`}}
										/>
								</label>
							)
						})}
					</ul>

					<div style={{margin: '0 0 0 auto'}}>
						{task && task.status && task.status.id == 5 ?
							<>Completed {dayjs(task.completed_at).format('D MMMM YYYY')}</>
						:
							<>Active <Duration date={task ? task.created_at : ''} largest={true}/></>
						}
					</div>

				</div>

				<div className='TaskViewScreen__buttons2'>

					<form onSubmit={async (e) => {
						e.preventDefault();
						if( await put({project_id: changedData.project_id}) ) {
							setHasChanges(true);
						}
					}}>
						<BTDSelectField 
							error={errors && errors.project_id ? errors.project_id : null} 
							height='slim'
							label='Project'
							onChange={async (value) => {
								if( await put({project_id: value}) ) {
									setData({...data, project_id: value});
									setHasChanges(true);
								}
							}} 
							options={projects}
							style={{marginBottom: '0', width: '250px'}}
							value={data.project_id ?? ''} 
						/>
					</form>

					{data.channel_id == 1 &&
						<BTDButton
							label='View in Helpscout'
							size='small'
							startIcon={<img src={HelpscoutIcon} alt='Helpscout Icon' style={{height: '20px', marginRight: '8px', width: '20px'}}/>}
							style={{height: '43px'}}
							type='outlined'
							url={'https://secure.helpscout.net/conversation/' + data.external_id}
							target='_blank'
						/>	
					}

					<div className='TaskViewScreen__buttons__icons'>

						<img 
							alt='Daily Update icon'
							className='TaskViewScreen__buttons__icon'
							onClick={async (e) => {
								e.preventDefault();
								e.stopPropagation();
								if( await put({daily_update: data.daily_update == 'yes' ? 'no' : 'yes'}, data.daily_update == 'yes' ? 'Removed from daily update' : 'Added to daily update') ) {
									setData({...data, daily_update: data.daily_update == 'yes' ? 'no' : 'yes'})
									setHasChanges(true);
								}
							}}
							src={data.daily_update == 'yes' ? SpeechIcon : NoSpeechIcon} 
						/>

						<ul className='TaskViewScreen__buttons__users'>
							{users && users.map((user, index) => {
								return(
									<label key={index} className="TaskViewScreen__buttons__users__user" style={{backgroundColor: user.colour}}>
										<input
											checked={data.user_id === user.id}
											className="TaskViewScreen__buttons__users__user__input"
											onChange={async () => {
												if( await put({user_id: user.id}) ) {
													setData({...data, user_id: user.id});
													setHasChanges(true);
												}
											}}
											type="radio"
											value={data.user_id}
											/>
										<UserInitials name={user.name}/>
									</label>
								)
							})}
						</ul>
					</div>


				</div>


				<h4 className='TaskViewScreen__label opacity'>Description</h4>
				
				<div className='TaskViewScreen__descriptionWrapper'>
					{descriptionFormActive ?
						<form onSubmit={async (e) => {
							e.preventDefault();
							if( await put({description: changedData.description}) ) {
								setHasChanges(true);
								setDescriptionFormActive(false);
							}
						}}>
							<TextEditor
								error={errors && errors.description ? errors.description : null} 
								label='Description'
								onChange={(value) => {
									setData({...data, description: value});
									setChangedData({...changedData, description: value});
								}} 
								style={{marginBottom: '10px'}}
								value={data.description ?? ''} 
								withHeader={false}
							/>

							<BTDButton
								label='Save'
								type='submit'
								formButton={true}
								size='small'
							/>
						</form>
					:
						isLoading ? <Skeleton width='100%'/> : <div className='TaskViewScreen__description' dangerouslySetInnerHTML={{__html: data.description}} onClick={() => setDescriptionFormActive(true)}></div>
					}
				</div>

				<h4 className='opacity centre'>Attachments</h4>

				<div className='TaskViewScreen__attachments'>

					<ImageUpload 
						error={errors.upload_ids ?? null}
						label='File upload'
						// image_url={upload ? upload.url : ''}
						// onChange={(value) => {setUpload(value)}}
						onChange={() => onChange()}
						// upload_id={upload ? upload.id : ''} 
						project_id={projectId}
						task_id={taskId}
						type='file'
					/>    

					<div>
						{task && task.attachments && task.attachments.map((attachment) => {
							return(
								<div 
									className='TaskViewScreen__attachments__attachment' 
									key={attachment.id} 
								>
									<DownloadPDF id={attachment.id} default_filename={attachment.name}>
										<div className='TaskViewScreen__attachments__attachment__name'>{attachment.name}</div>
									</DownloadPDF>
									<div onClick={() => {deleteAttachment(attachment.id)}}><ClearIcon colour='#e02323' height='20px' style={{ cursor: 'pointer', margin: '0 0 0 -3px', transform: 'translateY(3px)'}} /></div>
								</div>
							)
						})}
					</div>
				</div>

				<div className='TaskViewScreen__times'>

					<div className='TaskViewScreen__subtitle'>

						<h4 className='opacity'>Times</h4>

						<p className='opacity semi-bold'>{totalTime ? <><span>Total Time: </span> <Duration seconds={totalTime}/></> : ''}</p>

						{! show ?
							<BTDButton
								colour='green'
								label='Start Timer'
								onClick={() => {
									if(! data.project_id || data.project_id == 1) {
										alert('Please select a project!');
									}
									else {
										start(taskId) 
										onChange();
									}
								}}
								size='small'
								style={{height: '36px'}}
							/>
							:
							<BTDButton
								colour='red'
								label='Stop Timer'
								onClick={() => {
									stop()
									onChange();
								}}
								size='small'
								style={{height: '36px'}}
							/>
						}
					</div>

					{task && task.times && task.times.map((time) => {
						return(
							<div className='TaskViewScreen__times__time' key={time.id} style={{backgroundColor: time.is_running ? '#49bb082b' : '#fff',  border: time.is_running ? '1px solid #49bb082b' : 'none'}}>
								<BTDDatepicker
									error={timeErrors && timeErrors.started_at ? timeErrors.started_at : null}
									label='Started At'
									onChange={async (value) => {
										if(await updateTime({started_at: value}, 'Time updated!', time.id)) {
											onChange();
										}
									}}
									height='20px'
									style={{cursor: 'pointer', margin: '0 10px 0 0'}}
									value={time.started_at}
								/>
								<BTDDatepicker
									error={timeErrors && timeErrors.ended_at ? timeErrors.ended_at : null}
									label='Ended At'
									onChange={async (value) => {
										if(await updateTime({ended_at: value}, 'Time updated!', time.id)) {
											onChange();
										}
									}}
									height='20px'
									style={{cursor: 'pointer', margin: '0 10px 0 0'}}
									value={time.ended_at}
								/>
								<p style={{color: time.user ? time.user.colour : '#777'}}>{time.user ? time.user.name : ''}</p>
								<Duration seconds={time.seconds} />
								<div onClick={() => {deleteTime(time.id)}}><ClearIcon colour='#e02323' height='20px' style={{cursor: 'pointer'}} /></div>
							</div>
						)
					})}
				</div>

				<BTDButton
					colour='red'
					label='Delete Task'
					formButton={true}
					onClick={() => deleteItem()}
					size='small'
					style={{height: '36px', margin: '20px 0 0 auto'}}
				/>
					
			</div>
		</FormTemplate>

	)

}

TaskViewScreen.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
	projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

TaskViewScreen.defaultProps = {
    onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')},
    projectId: null,
	taskId: null
};

export default TaskViewScreen;